import React, { useMemo } from "react";
import { t } from "ttag";
import classNames from "classnames";
import { FinancingModalTrigger } from "tsi-common-react/src/apps/financing/FinancingModalTrigger";
import { getDefaultFinancingPlan } from "tsi-common-react/src/utils/financing";
import { formatFinancingPlan } from "tsi-common-react/src/utils/format";
import stearnsCc from "../../img/finance/stearns-cc.png";

interface IProps {
    applicationSource?: string;
}

const applyClassesDesktop = classNames({
    "button": true,
    "pre-approval-ad__button": true,
    "pre-approval-ad__apply": true,
    "al-financing-page__pre-approval-ad--apply-now": true,
    "al-financing-page__pre-approval-ad--apply-now-desktop": true,
});
const applyClassesMobile = classNames({
    "button": true,
    "pre-approval-ad__button": true,
    "pre-approval-ad__button--mobile": true,
    "pre-approval-ad__apply": true,
    "al-financing-page__pre-approval-ad--apply-now": true,
    "al-financing-page__pre-approval-ad--apply-now-mobile": true,
});
const preQualifyDesktop = classNames({
    "pre-approval-ad__see--link": true,
    "al-financing-page__pre-approval-ad--pre-qualify": true,
    "al-financing-page__pre-approval-ad--pre-qualify-desktop": true,
});
const preQualifyMobile = classNames({
    "pre-approval-ad__see--link": true,
    "al-financing-page__pre-approval-ad--pre-qualify": true,
    "al-financing-page__pre-approval-ad--pre-qualify-mobile": true,
});
const iconClasses = classNames({
    "pre-approval-ad__icon": true,
    "pre-approval-ad__icon--finance": true,
    "pre-approval-ad__icon--card": true,
});

export const FinancingModalTriggerFinance = (props: IProps) => {
    const plan = useMemo(
        () => formatFinancingPlan(getDefaultFinancingPlan()),
        [],
    );
    return (
        <span>
            <div className="finance-page__pre-approval-ad pre-approval-ad pre-approval-ad--finance-page u-flex-container">
                <img
                    className={iconClasses}
                    alt="Stearns Credit Card"
                    src={stearnsCc}
                />
                <div className="pre-approval-ad__terms">
                    <div className="pre-approval-ad__terms-line pre-approval-ad__terms-line--1">
                        {t`${plan.apr} APR`}
                    </div>
                    <div className="pre-approval-ad__terms-line pre-approval-ad__terms-line--2">
                        {t`for`}{" "}
                        <div className="pre-approval-ad__line-rule"></div>
                    </div>
                    <div className="pre-approval-ad__terms-line pre-approval-ad__terms-line--3">
                        {t`${plan.length} Months`}
                        <sup>
                            <a href="#terms_and_conditions">
                                {plan.superscript}
                                <span className="ada-screenreader-only">
                                    {t`Additional information about Terms and Conditions`}
                                </span>
                            </a>
                        </sup>
                    </div>
                </div>
                <FinancingModalTrigger
                    modalType="full-app"
                    applicationSource={props.applicationSource}
                    className={applyClassesDesktop}
                >
                    {t`Apply Now`}
                </FinancingModalTrigger>
                <div className="pre-approval-ad__see">
                    <div className="pre-approval-ad__see">
                        or check if you{" "}
                        <FinancingModalTrigger
                            modalType="prequal-app"
                            applicationSource={props.applicationSource}
                            className={preQualifyDesktop}
                        >
                            pre-qualify
                        </FinancingModalTrigger>
                    </div>
                    <div className="pre-approval-ad__see--small">
                        without affecting your credit score.
                    </div>
                </div>
            </div>
            <div className="finance-page__pre-approval-ad--mobile pre-approval-ad pre-approval-ad--finance-page u-flex-container">
                <div className="pre-approval-ad__icon-and-terms u-flex-container">
                    <div className="pre-approval-ad__icon-container u-flex-container">
                        <img
                            className="pre-approval-ad__icon pre-approval-ad__icon--mobile pre-approval-ad__icon--card"
                            alt="stearns credit card"
                            src={stearnsCc}
                        />
                    </div>
                    <div className="pre-approval-ad__terms pre-approval-ad__terms--mobile">
                        <div className="pre-approval-ad__terms-line pre-approval-ad__terms-line--1">
                            {t`${plan.apr} APR`}
                        </div>
                        <div className="pre-approval-ad__terms-line pre-approval-ad__terms-line--2">
                            {t`for`}{" "}
                            <div className="pre-approval-ad__line-rule"></div>
                        </div>
                        <div className="pre-approval-ad__terms-line pre-approval-ad__terms-line--3">
                            {t`${plan.length} Months`}
                            <sup>
                                <a href="#terms_and_conditions">
                                    {plan.superscript}
                                    <span className="ada-screenreader-only">
                                        {t`Additional information about Terms and Conditions`}
                                    </span>
                                </a>
                            </sup>
                        </div>
                    </div>
                </div>
                <FinancingModalTrigger
                    modalType="full-app"
                    className={applyClassesMobile}
                    applicationSource={props.applicationSource}
                >
                    {t`Apply Now`}
                </FinancingModalTrigger>
                <div className="pre-approval-ad__or-and-see">
                    <div className="pre-approval-ad__or pre-approval-ad__or--mobile">
                        or
                    </div>
                    <div className="pre-approval-ad__see">
                        Check if you
                        <FinancingModalTrigger
                            modalType="prequal-app"
                            className={preQualifyMobile}
                            applicationSource={props.applicationSource}
                        >
                            <strong>pre-qualify</strong>
                        </FinancingModalTrigger>
                        without affecting your credit score.
                    </div>
                </div>
            </div>
        </span>
    );
};
